<template>
    <div v-if="questionBank && questionBank.examQuestionsBank" class="ma-3">
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="d-flex align-center rounded-lg pa-2 px-4" flat>
            <h4 class="">
              {{
                questionBank.categoryName
                  ? `${questionBank.categoryName} / ${questionBank.studySubject.subjectName}`
                  : ""
              }}
            </h4>

            <v-spacer></v-spacer>
            <v-btn tile color="primary" @click="back()" class="rounded-xl">
              {{ $t("back") }}
              <v-icon right> mdi-arrow-left </v-icon>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="9" sm="12">
          <v-card class="align-center rounded-lg pa-2 px-4" flat>
            <v-expand-transition>
              <Question
                :key="componentsKeys[0]"
                :examQuestions="questionBank.examQuestionsBank"
                :examGuid="null"
                :categoryGuid="questionBank.categoryGuid"
                :isActive="false"
                :isBankQuestions="true"
                v-on:send-result-values="getQuestions(0)"
                v-if="selectedItem == 0"
              ></Question>
              <p v-else></p>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <v-card class="align-center rounded-lg" flat>
            <v-list rounded>
              <!-- <v-subheader>REPORTS</v-subheader> -->
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item class="" v-for="(item, i) in items" :key="i">
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-card flat class="align-center rounded-lg mt-3">
            <v-card-text dir="auto" style="white-space: pre-line !important">
              <v-data-table
                :headers="headers"
                :items="questionsCount"
                item-key="questionType"
                hide-default-header
                hide-default-footer


              ></v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import Question from "../../components/exams/question.vue";

export default {
  components: {
    Question,
  },
  props: ["id"],
  data() {
    return {
      headers: [
      { text: "questions", value: "questionType" },
        { text: "count", value: "count" },
      ],
      questionsCount: [],
      componentsKeys: [
        Math.random(),
        Math.random(),
        Math.random(),
        Math.random(),
      ],
      questionBank: {},
      randomQuestion: null,
      overlay: false,
      selectedItem: 0,
      items: [
        {
          text: this.$t("exam.questionsCount"),
          icon: "mdi-frequently-asked-questions",
        },
      ],
    };
  },

  methods: {
    getQuestions(keyval) {
      this.overlay = true;

      this.axios
        .get(`Exams/GetBankQuestions?categoryGuid=${this.id}`)
        .then((res) => {
          // console.log(res);
          this.questionBank = res.data.data;
          this.getQuestionsCount();
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
          if (keyval) {
            this.componentsKeys[keyval] = Math.random();
            this.$forceUpdate();
          }
        });
    },
    back() {
      this.$router.go(-1);
    },

    getQuestionsCount() {
      if (this.questionBank && this.questionBank.examQuestionsBank) {
        this.questionsCount = [

          {
            questionType: this.$t("exam.FillInTheBlank"),
            count: this.questionBank.examQuestionsBank.filter(
              (m) => m.questionType == "FillInTheBlanks"
            ).length,
          },
          {
            questionType: this.$t("exam.FillInTheBlankNumericValue"),
            count: this.questionBank.examQuestionsBank.filter(
              (m) => m.questionType == "Number"
            ).length,
          },
          {
            questionType: this.$t("exam.MultipleChoices"),
            count: this.questionBank.examQuestionsBank.filter(
              (m) => m.questionType == "MultiChoice"
            ).length,
          },
          {
            questionType: this.$t("exam.ChooseOne"),
            count: this.questionBank.examQuestionsBank.filter(
              (m) => m.questionType == "OneChoice"
            ).length,
          },
          {
            questionType: this.$t("exam.UploadAFile"),
            count: this.questionBank.examQuestionsBank.filter(
              (m) => m.questionType == "AttachedFile"
            ).length,
          },
          {
            questionType: this.$t("exam.TheTotalNumberOfQuestions"),
            count: this.questionBank.examQuestionsBank.length,
          },
        ];
      } else this.questionsCount = [];
    },
  },
  created() {
    this.getQuestions();
  },
  beforeCreate() {},
  mounted() {},
};
</script>

  <style></style>
